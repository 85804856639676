.notification_container {
  position: fixed;
  flex-shrink: 0;
  top: 10%;
  height: auto;
  width: 30%;
  max-height: 60%;
  overflow: auto;
  cursor: pointer;
  right: 5%;
  background: rgb(0 0 0 / 29%);
  backdrop-filter: blur(5px);
  border-radius: 4px;
}

.description_st {
  overflow: hidden;
  color: "red";
  /* white-space: nowrap; */
  text-overflow: ellipsis;
}

.dashboard_notification_history_container {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 75%;
  height: 75%;
  left: 42px;
  top: 161px;

  /* .notification_bar {
        cursor: pointer;
        margin: 30px 0;

        /* &:first-child {
        margin-top: 0px;
        } */

  /* .notification_mark {
        width: 50px;
        height: 5px;
        background: #a8ddea;
        box-shadow: 1px 2px 17px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        margin-bottom: 10px;
        } */

  /* .notification_time {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #989898;
        } */

  /* .notification_message {
        height: 18px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #262d37;
        } */
}
/* } */

.notification_message {
  height: 18px;
  /* font-family: "DM Sans"; */
  /* font-style: normal; */
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #262d37;
}
