@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");

.inline-errormsg {
  color: #dc3545;
  font-size: 12px;
}

.hidden {
  display: none;
}

.selected {
  background-color: lightblue;
}

.MuiButton-label {
  font-family: "Montserrat", sans-serif !important;
}

.custom-file {
  padding: 6px !important;
  border: 1px solid #3f51b5 !important;
  border-radius: 5px !important;
  outline: none !important;
}

.home-link {
  color: #fff;
}

.home-link:hover {
  color: #fff;
  text-decoration: none !important;
}

.main-wrapper {
  position: relative;
  padding: 0 0 60px;
  min-height: 100vh;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #3f51b5;
  padding: 20px 0px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.custom-thead {
  color: #fff;
  background-color: #3f51b5;
}

.search-bar {
  border: 2px solid #3f51b5;
  border-radius: 6px;
}

.backBtn {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 50%;
  cursor: pointer;
}

.makeLink {
  color: rgb(36, 128, 248);
  cursor: pointer;
}
.makeLink:hover {
  text-decoration: underline;
}

.helpUL li {
  margin: 10px;
}



.highcharts-figure,
.highcharts-data-table table {
  min-width: 360px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}
.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}
.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}
.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}
.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

#widgetContainer .card {
  border: 2px solid rgb(63 81 181) !important;
  overflow: hidden;
}

#widgetContainer .card-header {
  background-color: rgb(63 81 181) !important;
  border-bottom: 2px solid rgb(63 81 181) !important;
  overflow: hidden;
  color: white !important;
}

#publicViewGraphCardRow .card {
  border: 2px solid rgb(63 81 181) !important;
  overflow: hidden;
}

#publicViewGraphCardRow .card-header {
  background-color: rgb(63 81 181) !important;
  border-bottom: 2px solid rgb(63 81 181) !important;
  overflow: hidden;
  color: white !important;
}

.border_black {
  border: 1px solid black;
}

.makeTextFromA {
  color: white;
  text-decoration: none;
}
.makeTextFromA:hover {
  color: white;
  text-decoration: none;
}

.width100px {
  width: 140px !important;
}

.borderSuccess {
  border: 2px solid #5af13e !important;
}

.borderDanger {
  border: 2px solid #ff4a4a !important;
}

.wrapText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.numericVisualBox {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  overflow: hidden;
  width: 230px !important;
  border: 2px solid #3f51b5 !important;
  cursor: pointer;
}

.graphWidgetCard {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  background-color: #3f51b5 !important;
}

.b_radius {
  border-radius: 4px !important;
}

.c-pointer {
  cursor: pointer;
}

.border-primary {
  border: 2px solid #3f51b5 !important;
}

.col_divider {
  border-right: 0.1px solid #e6dddd;
}

.SideNavigation{
  position:fixed;
  top:10vh ;
  right: 0;
  width: 16%;
  height: 90vh;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.SoilingNav_Head{
  padding: 8px;
  position: relative;
  font-weight: 900;
  width: 99%;

}
.SoilingNav_Body{
  position: relative;
  width: 100%;
  height: 70vh;
  /* background-color: green; */
  overflow: auto;
  border-top: 2px solid rgba(0, 0, 0, 0.171);
  padding: 2px;
}
.SoilingNav_Body ul{
  text-decoration: none;
  position: relative;
  left: -28px;
  margin-top: 10px;
}
.SoilingNav_Body ul li{
  margin-top: 15px;
  list-style: none;
  cursor: pointer;
  text-align: center;

}

.publicChannelCard {
  position: relative;
  right: 2px;
  width: 85%;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  overflow: hidden;
}
.mainSoilingChannelCard {
  position: relative;
  right: 0;
  display: flex;
  flex-wrap: wrap;
}
.soilingChannelCard {
  border-radius: 4px;
  overflow: hidden;
}
.basic-single .css-b62m3t-container {
  width: 100%;
}

.custom_card {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  background-color: white;
}

#DatePicker .modal-content {
  width: auto !important;
  margin: auto !important;
  z-index: 5000 !important;
}

.MuiAppBar-root {
  z-index: 1000 !important;
}

/* .styles_topmenu__oGT_u {
  position: absolute !important;
  right: 10% !important;
  top: 3% !important;
} */

/* .styles_carouselcontrolnext__DoQ9- {
  position: absolute;
  right: 7% !important;
  top: 45% !important;
} */

#react-select-2-listbox > {
  color: white !important;
  z-index: 100 !important;
}

.react-select-wrapper {
  width: 100% !important;
}

.select__control {
  max-height: 200px !important;
  overflow: auto !important;
}

::-webkit-scrollbar {
  width: 5px !important;
  background-color: #e6e6e6;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.3125rem;
  background-color: #3f51b5;
  border: 2px solid #3f51b5;
}

::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.generate_certification {
  margin-top: 50px;
}
.generate_certification > div {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #4c0ab8;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4c0ab8;
  border-color: #4c0ab8;
}
